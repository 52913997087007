import React from 'react'
import { LazyLoadImage } from "react-lazy-load-image-component";

import SDG1 from '../../assets/images/section-one/SDG1.png'
import SDG4 from '../../assets/images/section-one/SDG4.png'
import SDG5 from '../../assets/images/section-one/SDG5.png'
import SDG10 from '../../assets/images/section-one/SDG10.png'
import img1 from '../../assets/images/section-one/img1.png'
import img2 from '../../assets/images/section-one/img2.png'

import { motion } from "framer-motion";

const WelcomeSection = () => {
  return (
    <article className="welcome-section flex-cont" >
      <section className="section-2-info flex-container flex-column txt-center pop-font" >
        <motion.div
          className='pizza-motion'
          initial={{ opacity: 0, translateX: -200 }}
          whileInView={{
            opacity: 1, translateX: -100,
            translateY: '-12%'
          }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3 }}
        >
          {/*<img src={PizzaTwo} alt="" className=" pizza-two" /> */}
        </motion.div>
        <motion.div
          className='pizza-motion'
          initial={{ opacity: 0, translateX: 200 }}
          whileInView={{
            opacity: 1, translateX: 100,
            translateY: '-12%'
          }}
          exit={{ opacity: 0 }}
          transition={{ duration: 4 }}
        >
         {/* <img src={PizzaOne} alt="" className=" pizza-one" />  */}
        </motion.div>
        <h2 style={{ textAlign: 'left', marginLeft: 'auto', marginRight: 'auto' }} className="txt-black">
          WELCOME TO  <span className="signature-font" >Carole Kinoti Brand </span>
        </h2>
        <p>
        Carole Kinoti is an Award winning entrepreneur in the fashion industry with over 20 years of
        experience who believes in using fashion to empower and elevate women, youth (the forgotten lot)
        and those with learning disabilities. This has led her to develop several initiatives inspired by the
        challenges she has faced in the industry. The Brand offers sustainable and ethical wardrobe
        solutions
        </p>
      </section>
      {/** */}
      <div className="banner">
  <LazyLoadImage
    className="banner-img img1" // Add the "img1" class here
    src={img1}
    alt="Image 1"
  />
  <div className="atext-container">
    <span className="signature-font">Carole Kinoti Brand</span>
    <h2>YOUR HOME FOR MADE IN KENYA UNIQUE PIECES TAILORED WITH LOVE</h2>
    <div className="sdg-images">
      <LazyLoadImage
        className="banner-img"
        src={SDG1}
        alt="sdg 1"
      />
      <LazyLoadImage
        className="banner-img"
        src={SDG4}
        alt="sdg 4"
      />
      <LazyLoadImage
        className="banner-img"
        src={SDG5}
        alt="sdg 5"
      />
      <LazyLoadImage
        className="banner-img"
        src={SDG10}
        alt="SDG 10"
      />
    </div>
  </div>
  <LazyLoadImage
    className="banner-img img2" // Add the "img2" class here
    src={img2}
    alt="Image 2"
  />
</div>

    </article>
  )
}

export default WelcomeSection;