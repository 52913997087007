import React from 'react'
import { motion } from "framer-motion";
import "react-alice-carousel/lib/alice-carousel.css";
import landingpg from '../../assets/images/hero/landingpg.png'
import HeroVideo from '../../assets/images/hero/hero-bg.mp4';

const Hero = () => {
  return (
    <section className="hero-section">
      
      <video autoPlay loop muted playsInline className="hero-video">
        <source src={HeroVideo} type="video/mp4" />
      </video>
      <section className="header-info txt-center pop-font txt-white">
        <div className="flex-container" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <motion.div
            className="flex-item flex-text"
            initial={{ opacity: 0, translateX: 300 }}
            whileInView={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 2 }}
          >
            <span className="signature-font">Carole Kinoti Brand</span>
            <p className="txt-white" style={{ marginLeft: '0', paddingLeft: '0' }}>
            Sustainable Kenyan fashion, elegantly crafted with eco-conscious love.
            </p>
            {/**
            <div className="header-btns">
              <Link className="passive-button-style" to="/blog">
                Read Blog
              </Link>
              <Link className="passive-button-style" to="/menu">
                View Collection
              </Link>
             
            </div>
            */}
          </motion.div>

          <div className="flex-item flex-image">
            <img src={landingpg} alt="" className="landingpg-img" />
          </div>
        </div>
      </section>
    </section>
  );
}


export default Hero;