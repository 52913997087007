import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import validateForm from "../../components/validateForm";
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from "react-router-dom";
import ResetLocation from "../../helpers/ResetLocation";


const Form = () => {
  const [formValue, setFormValue] = useState({
    fullname: "",
    email: "",
    message: "",
    dob: "",
    county: "",
    idNumber: "",
    kcseIndexNo: "",
    kcpeIndexNo: "",
    phoneNumber: "",
  });
  const [submit, setSubmit] = useState(false);
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = validateForm("form");

  useEffect(() => {
    document.title = "form | Carole Kinoti Brand";
  }, []);


  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    setFormError(validate(formValue));
    setSubmit(true);
    
    if (Object.keys(validate(formValue)).length > 0) {
      setLoading(false);
      setSubmit(false);
      return;
    } else {
      // Handle form submission logic here
      try {
        const response = await fetch('YOUR_FORM_SUBMISSION_ENDPOINT', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formValue),
        });
  
        if (response.status === 200) {
          setLoading(false);
          setSubmit(true);
          ResetLocation();
          setFormValue({
            fullname: '',
            email: '',
            dob: '',
            county: '',
            idNumber: '',
            kcseIndexNo: '',
            kcpeIndexNo: '',
            phoneNumber: '',
            message: '',
          });
          // Clear any previous form errors
          setFormError({});
        } else {
          // Handle unsuccessful form submission (e.g., show an error message)
          // You can update the UI or set an error state here
          console.error('Form submission failed:', response.statusText);
          setLoading(false);
          setSubmit(false);
        }
      } catch (error) {
        console.error('Error submitting the form:', error.message);
        setLoading(false);
        setSubmit(false);
      }
    }
  };
  
  const handleValidation = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };
  
  return (
    <motion.div
      initial={{ opacity: 0, translateX: -300 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: -300 }}
      transition={{ duration: 1 }}
    >
      <main className="form">
        {loading ? (
          <section className="form-loader">
            <p>Almost there...</p>
            <img
              alt="Processing request"
              src="https://media0.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif?cid=ecf05e472hf2wk1f2jou3s5fcnx1vek6ggnfcvhsjbeh7v5u&ep=v1_stickers_search&rid=giphy.gif&ct=s"
            />
          </section>
        ) : (
          submit && Object.keys(formError).length === 0 ? (
            <section className="form-success">
              <p>We have received your message and we will get back to you shortly! 🍕</p>
              <section>
                <Link className="active-button-style" to="/menu">
                  Go to menu
                </Link>
                <button
                  className="passive-button-style"
                  type="button"
                  onClick={() => {
                    setSubmit(false);
                  }}
                >
                  Send again
                </button>
              </section>
            </section>
          ) : (
            <form onSubmit={handleSubmit} className="flex-container flex-column">
              <div className="webflow-style-input">
                <input
                  onChange={handleValidation}
                  value={formValue.fullname}
                  name="fullname"
                  className="pop-font"
                  type="text"
                  placeholder="Full Name"
                />
              </div>
              <span className="input-validation-error">{formError.fullname}</span>
  
              <div className="webflow-style-input">
                <input
                  onChange={handleValidation}
                  value={formValue.email}
                  name="email"
                  className="pop-font"
                  type="text"
                  placeholder="Email"
                />
              </div>
              <span className="input-validation-error">{formError.email}</span>
  
              <div className="webflow-style-input">
                <input
                  onChange={handleValidation}
                  value={formValue.dob}
                  name="dob"
                  className="pop-font"
                  type="text"
                  placeholder="Date of Birth"
                />
              </div>
              <span className="input-validation-error">{formError.dob}</span>
  
              <div className="webflow-style-input">
                <input
                  onChange={handleValidation}
                  value={formValue.county}
                  name="county"
                  className="pop-font"
                  type="text"
                  placeholder="County in Nairobi"
                />
              </div>
              <span className="input-validation-error">{formError.county}</span>
  
              <div className="webflow-style-input">
                <input
                  onChange={handleValidation}
                  value={formValue.idNumber}
                  name="idNumber"
                  className="pop-font"
                  type="text"
                  placeholder="ID Number"
                />
              </div>
              <span className="input-validation-error">{formError.idNumber}</span>
  
              <div className="webflow-style-input">
                <input
                  onChange={handleValidation}
                  value={formValue.kcseIndexNo}
                  name="kcseIndexNo"
                  className="pop-font"
                  type="text"
                  placeholder="KCSE Index No"
                />
              </div>
              <span className="input-validation-error">{formError.kcseIndexNo}</span>
  
              <div className="webflow-style-input">
                <input
                  onChange={handleValidation}
                  value={formValue.kcpeIndexNo}
                  name="kcpeIndexNo"
                  className="pop-font"
                  type="text"
                  placeholder="KCPE Index No"
                />
              </div>
              <span className="input-validation-error">{formError.kcpeIndexNo}</span>
  
              <div className="webflow-style-input">
                <input
                  onChange={handleValidation}
                  value={formValue.phoneNumber}
                  name="phoneNumber"
                  className="pop-font"
                  type="text"
                  placeholder="Phone Number"
                />
              </div>
              <span className="input-validation-error">{formError.phoneNumber}</span>
  
              
              <span className="input-validation-error">{formError.message}</span>
  
              <button type="submit" className="active-button-style">
                Send
              </button>
            </form>
          )
        )}
  
        <section className="form-us-content pop-font">
          <section className="form-us-content-txt">
            <h2 className="">form us</h2>
            <p>
              We greatly anticipate your response and are eager to receive any inquiries you might have. Please do not hesitate to reach out to us should you require any further clarification or assistance. Your feedback and questions are of utmost importance to us, and we are here to provide the support you need. Looking forward to hearing from you!
            </p>
          </section>
        </section>
      </main>
    </motion.div>
  );
  
}


export default Form;