import React from 'react'
import Tilt from 'react-parallax-tilt';
import { motion } from "framer-motion";
import ourServices from '../../data/ourServices';



const OurServices = () => {
  return (
    <motion.div
      initial={{ opacity: 0, translateX: -300 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
    >
      <article className="section-3-container" >
        <section>
          <h2 className="pop-font">Empowerment programs</h2>
          <p className="pop-font section-description">
          Discover fashion that speaks to both your style and your values. 
          Unveil your unique persona through our curated collections that 
          effortlessly blend boldness, tradition, and dynamic 
          versatility—all while prioritizing sustainability.
          </p>
        </section>
        <section className="services-grid flex-container flex-column">
  {ourServices.map((service) => (
    <Tilt key={service.id}>
      <section className="service-list flex-container flex-column">
        <img
          
          className="service-img"
          src={service.img}
          alt=""
          aria-hidden="true"
        />

        <section>
          <h3 className="pop-font" style={{ color: 'black' }}>{service.name}</h3>
          <p style={{ color: 'black' }}>{service.description}</p>
        </section>
      </section>
      </Tilt>
  ))}
</section>

      </article>
    </motion.div>
  )
}

export default OurServices;