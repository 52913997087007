import CocaCola from '../assets/images/drinks-images/coca-cola-classic.jpeg'
import MountainDew from '../assets/images/drinks-images/Mountain Dew Citrus.webp'
import PepsiCola from '../assets/images/drinks-images/Pepsi Cola.jpeg'
import Milo from '../assets/images/drinks-images/milos.jpeg'
import DrPepper from '../assets/images/drinks-images/Diet Dr Pepper Soda.jpg'
import SpriteCaffeine from '../assets/images/drinks-images/Sprite Caffeine-Free Lemon Lime Soda Pop.jpeg'
import GreatValueSweet from '../assets/images/drinks-images/Great Value.jpeg'
import SToK from '../assets/images/drinks-images/SToK.webp'
import Lipton from '../assets/images/drinks-images/Lipton Green Tea.jpeg'
import CanadaDry from '../assets/images/drinks-images/Canada Dry Ginger.jpg'
import DrPepperZero from '../assets/images/Collections/LAD collection/WhatsApp Image 2023-09-12 at 8.30.56 AM (3).jpeg'
import DietDrPepper from '../assets/images/Collections/LAD collection/WhatsApp Image 2023-09-12 at 8.30.56 AM (3).jpeg'
import AquafinaPurified from '../assets/images/drinks-images/Aquafina Purified.jpeg'
import PureLife from '../assets/images/Collections/Athleisure Collection/WhatsApp Image 2023-09-13 at 8.11.57 AM.jpeg'
import SamsCola from '../assets/images/Collections/Blanketi Collection/WhatsApp Image 2023-09-12 at 8.28.45 AM.jpeg'
import RedBull from '../assets/images/Collections/Afro Wear Collection/Afro4.jpeg'
import Anw from '../assets/images/Collections/Afro Wear Collection/Afro3.jpeg'
import PepsiSoda from '../assets/images/Collections/Afro Wear Collection/Afro2.jpeg'
import GreatValue from '../assets/images/Collections/Afro Wear Collection/WhatsApp Image 2023-09-12 at 8.01.42 AM.jpeg'
import Sunkist from '../assets/images/Collections/Dhahabu/Dhahabu3.jpeg'
import GreatValueTwist from '../assets/images/Collections/Afro Wear Collection/Afro1.jpeg'
import FantaOrange from '../assets/images/Collections/Afro Wear Collection/Afro.jpeg'
//Pasta
import Hariri from '../assets/images/Collections/Hariri Collections/Hariri.jpeg'
import ChickenAlfredo from '../assets/images/Collections/Hariri Collections/Hariri2.jpeg'
import AllaGricia from '../assets/images/Collections/Athleisure Collection/WhatsApp Image 2023-09-13 at 8.11.58 AM (1).jpeg'
import SheetPan from '../assets/images/pasta-images/Sheet-Pan Burrata Caprese Gnocchi.jpeg'
import AllaVodka from '../assets/images/pasta-images/Penne Alla Vodka.jpeg'
import BakedSpaghetti from '../assets/images/pasta-images/Baked Spaghetti.jpeg'
import PotatoGnocchi from '../assets/images/pasta-images/pasta-6.jpeg'
import BakedZiti from '../assets/images/pasta-images/Ultimate Baked Ziti.jpeg'
import CacioPepe from '../assets/images/pasta-images/Cacio e Pepe.jpeg'
import PastaPuttanesca from '../assets/images/pasta-images/Pasta Puttanesca.jpeg'
import ItalianPastaSalad from '../assets/images/pasta-images/Italian Pasta Salad.jpeg'
import Lasagna from '../assets/images/pasta-images/Lasagna Roll Ups.jpeg'
import ToastedRavioli from '../assets/images/pasta-images/Toasted Ravioli.jpeg'
import OrecchietteBroccoli from '../assets/images/pasta-images/Orecchiette with Broccoli Rabe.jpeg'
import LambRagu from '../assets/images/pasta-images/Lamb Ragù.jpeg'
import LasagnaBolognese from '../assets/images/pasta-images/Lasagna Bolognese.jpeg'
import LemonRicotta from '../assets/images/pasta-images/Lemon Ricotta Pasta.jpeg'
import StuffedRigatoni from '../assets/images/pasta-images/Stuffed Rigatoni.jpeg'
import FiveCheese from '../assets/images/pasta-images/Five-Cheese Ziti Al Forno.jpeg'
import ChickenFlorentine from '../assets/images/Collections/Hariri Collections/Hariri1.jpeg'
import MacCheese from '../assets/images/Collections/Hariri Collections/Hariri.jpeg'
//Pizza
import CheesePizza from '../assets/images/Collections/Athleisure Collection/Athleisure3.jpeg'
import ExtraCheesePizza from '../assets/images/cheese-pizza-376.jpg'
import VeggiePizza from '../assets/images/veggie-pizza.jpg'
import PepperoniPizza from '../assets/images/Collections/Blanketi Collection/Blanketi3.jpeg'
import MeatPizza from '../assets/images/meat-pizza.jpg'
import MargheritaPizza from '../assets/images/Collections/Blanketi Collection/Blanketi1.jpeg'
import BBQPizza from '../assets/images/Collections/Athleisure Collection/Athleisure1.jpeg'
import AllaPalla from '../assets/images/pizza-alla-pala.jpg'
import Prosciutto from '../assets/images/Prosciutto-e-Funghi-Pizza.jpeg'
import DetroitStyle from '../assets/images/Detroit-Style-pizza.jpeg'
import GarlicFingers from '../assets/images/Garlic-Fingers-pizza.jpeg'
import StLous from '../assets/images/St-Louis-Style-Pizza.jpg'
import Rustica from '../assets/images/Pizza-Rustica.jpg'
import Genovese from '../assets/images/Genovese.jpeg'
import Efichi from '../assets/images/Collections/Blanketi Collection/Blanketi4.jpeg'
import Apizza from '../assets/images/Collections/Blanketi Collection/Blanketi.jpeg'
import StuffedPizza from '../assets/images/Collections/Blanketi Collection/Blanketi5.jpeg'
import Pugliese from '../assets/images/Pugliese.jpeg'
import TomatoPie from '../assets/images/Tomato-Pie.jpeg'
import Fugaza from '../assets/images/fugazza.jpeg'
import DiZucca from '../assets/images/di-zucca.jpeg'
import Fugazeta from '../assets/images/Fugazzeta.jpeg'
import Carbonara from '../assets/images/carbonara.jpeg'
import GranmaPie from '../assets/images/Grandma-Pie.jpeg'
import Padellino from '../assets/images/padellino.jpeg'
import CaliforniaStyle from '../assets/images/California-Style.jpeg'
import Fritta from '../assets/images/fritta.jpeg'
import Romana from '../assets/images/romana.jpeg'
import ThinCrust from '../assets/images/Thin-Crust.jpeg'
import GreekStyle from '../assets/images/Greek-Style.jpeg'
import PizzaRomana from '../assets/images/PizzaRomana.jpeg'
//Sushi
import SalmonPoke from '../assets/images/sushi-images/sushi-19.jpeg'
import VeggiePoke from '../assets/images/sushi-images/sushi-26.jpeg'
import MiniSalmon from '../assets/images/sushi-images/sushi-12.jpeg'
import OmbosSeth from '../assets/images/sushi-images/sushi-23.jpeg'
import VeggieSet from '../assets/images/sushi-images/sushi-27.jpeg'
import FriendlySet from '../assets/images/sushi-images/sushi-8.jpeg'
import SunnyPhiladelphia from '../assets/images/sushi-images/sushi-22.jpeg'
import HiddenDragon from '../assets/images/sushi-images/sushi-9.jpeg'
import HottestTaisho from '../assets/images/sushi-images/sushi-24.jpeg'
import BurningTaisho from '../assets/images/sushi-images/sushi-3.jpeg'
import BlissfulEel from '../assets/images/sushi-images/sushi-2.jpeg'
import Sebastian from '../assets/images/Collections/Dhahabu/Dhahabu3.jpeg'
import DoubleSalmon from '../assets/images/Collections/Dhahabu/Dhahabu1.png'
import ClockworkOrange from '../assets/images/Collections/Athleisure Collection/Athleisure.jpeg'
import KiwiRoll from '../assets/images/sushi-images/sushi-11.jpeg'
import Siamese from '../assets/images/sushi-images/sushi-25.jpeg'
import SalmonMaki from '../assets/images/sushi-images/sushi-18.jpeg'
import NigiriWithRoasted from '../assets/images/sushi-images/sushi-15.jpeg'
import NigiriWithSalmon from '../assets/images/Collections/Dhahabu/Dhahabu.png'
import NigiriWithEel from '../assets/images/Collections/Dhahabu/Dhahabu Coll.jpeg'
import VulcanTobiko from '../assets/images/Collections/Athleisure Collection/Athleisure4.jpeg'
import SalmonGunkan from '../assets/images/Collections/Athleisure Collection/Athleisure2.jpeg'
import VeryVegetarian from '../assets/images/Collections/LAD collection/lad1.jpeg'
import SushiOne from '../assets/images/Collections/LAD collection/lad.jpeg'
import CucumberMaki from '../assets/images/Collections/LAD collection/lad2.jpeg'
import HungrySet from '../assets/images/sushi-images/sushi-10.jpeg'
import NigiriOcean from '../assets/images/sushi-images/sushi-13.jpeg'
import FoggyAlbion from '../assets/images/sushi-images/sushi-7.jpeg'
import StanleyKubrick from '../assets/images/sushi-images/sushi-21.jpeg'

export const allProductsData = [
  

  {
    id: 'a-w-caffeine-free-low-sodium-root-beer-soda-pop-second',
    ItemImg: DrPepperZero,
    ItemName: 'LAD Collection',
    Category: 'CKB Collections',
    attributes: [],
  },
  {
    id: 'pepsi-soda-pop-second',
    ItemImg: PureLife,
    ItemName: 'Athleisure',
    Category: 'CKB Collections',
    attributes: [],
  },
  {
    id: 'great-value-cream-soda-pop-second',
    ItemImg: GreatValue,
    ItemName: 'Afro Collection',
    Category: 'CKB Collections',
    attributes: [],
  },
  {
    id: 'sunkist-orange-soda-second',
    ItemImg: Sunkist,
    ItemName: 'Dhahabu Collection',
    ItemPrice: (1).toFixed(2),
    Category: 'CKB Collections',
    attributes: [],
  },
  {
    id: 'great-value-twist-up-lemon-lime-soda-pop-second',
    ItemImg: SamsCola,
    ItemName: 'Blanketi Collection',
    Category: 'CKB Collections',
    attributes: [],
  },
  
  {
    id: 'pepperoni-pizza',
    ItemImg: StuffedPizza,
    ItemName: 'Blanketi Piece',
    ItemIngredients:
      'Blanketi Piece, requires a 6 piece set.',
    ItemPrice: (5).toFixed(2),
    Category: 'Blanketi',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
 
  
  
  {
    id: 'margherita-pizza-second',
    ItemImg: MargheritaPizza,
    ItemName: 'Blanketi Collection',
    ItemIngredients:
      'Requires 6 pieces per collection.',
    ItemPrice: (8).toFixed(2),
    Category: 'Blanketi',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  
  
 
  
 
  {
    id: 'apizza-pizza-second',
    ItemImg: Apizza,
    ItemName: 'Blanketi Collection',
    ItemIngredients:
      'Comfy and Cozy.',
    ItemPrice: (11).toFixed(2),
    Category: 'Blanketi',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  
  
  
  
  {
    id: 'chicken-alfredo',
    ItemImg: ChickenAlfredo,
    ItemName: 'Hariri Item',
    ItemIngredients:
      '6 item collection fot Hariri Collection.',
    ItemPrice: (2).toFixed(2),
    Category: 'Hariri',
    attributes: [],
  },



  {
    id: 'italian-mac-and-cheese',
    ItemImg: MacCheese,
    ItemName: 'Hariri',
    ItemIngredients:
      'Italian sausage, pasta, cheese sauce, cream, baby spinach.',
    ItemPrice: (8).toFixed(2),
    Category: 'Hariri',
    attributes: [],
  },
  {
    id: 'chicken-alfredo-second',
    ItemImg: ChickenAlfredo,
    ItemName: 'Hariri Attire',
    ItemIngredients:
      'Requires a 6 piece collection for purchase.',
    ItemPrice: (2).toFixed(2),
    Category: 'Hariri',
    attributes: [],
  },


  {
    id: 'chicken-florentine-pasta-second',
    ItemImg: ChickenFlorentine,
    ItemName: 'Hariri Collection',
    ItemIngredients:
      'Hariri item collection needs a minimum of 6 piece purchase.',
    ItemPrice: (10).toFixed(2),
    Category: 'Hariri',
    attributes: [],
  },


  {
    id: 'athleisure',
    ItemImg: DoubleSalmon,
    ItemName: 'Athleisure',
    ItemIngredients:
      'Salmon, Cream Cheese, Avocado, Sesame, Extra Salmon Slice, Cucumber, Rice, nori.',
    ItemPrice: (14).toFixed(2),
    Category: 'Athleisure',
    attributes: [],
  },




  {
    id: 'nigiri-with-salmon-and-orange-1-pc',
    ItemImg: NigiriWithSalmon,
    ItemName: 'Dhahabu 6 piece Set',
    ItemIngredients: 'Dhahabu 6 piece minimum Set',
    ItemPrice: (4).toFixed(2),
    Category: 'Dhahabu',
    attributes: [],
  },








  {
    id: 'sebastian-the-crab-roll-8-pcs-second',
    ItemImg: Sebastian,
    ItemName: 'Dhahabu Collection',
    ItemIngredients:
      'Dhahabu Collection.',
    ItemPrice: (13).toFixed(2),
    Category: 'Dhahabu',
    attributes: [],
  },
  {
    id: 'double-salmon-roll-8-pcs-sec',
    ItemImg: DoubleSalmon,
    ItemName: 'Dhahabu unique set',
    ItemIngredients:
      'Dhahabu unique 6 piece set.',
    ItemPrice: (10).toFixed(2),
    Category: 'Dhahabu',
    attributes: [],
  },

  {
    id: 'nigiri-with-salmon-and-orange-1-pc-second',
    ItemImg: NigiriWithEel,
    ItemName: 'Dhahabu Collection',
    ItemIngredients: 'Dhahabu Collection, 6 piece requirements.',
    ItemPrice: (15),
    Category: 'Dhahabu',
    attributes: [],
  },









  {
    id: 'great-value-twist-up-lemon-lime-soda-pop',
    ItemImg: GreatValueTwist,
    ItemName: 'Afro Collection',
    ItemPrice: (2).toFixed(2),
    Category: 'Afro Collection',
    attributes: [],
  },
  {
    id: 'fanta-orange-soda',
    ItemImg: FantaOrange,
    ItemName: 'Afro Wear',
    ItemPrice: (1).toFixed(2),
    Category: 'Afro Collection',
    attributes: [],
  },



  {
    id: 'red-bull-energy-drink-second',
    ItemImg: RedBull,
    ItemName: 'Afro Attire',
    ItemPrice: (2).toFixed(2),
    Category: 'Afro Collection',
    attributes: [],
  },
  {
    id: 'a-w-caffeine-free-low-sodium-root-beer-soda-pop-second',
    ItemImg: DietDrPepper,
    ItemName: 'Afro Wear',
    ItemPrice: (3).toFixed(2),
    Category: 'Afro Collection',
    attributes: [],
  },
  {
    id: 'pepsi-soda-pop-second',
    ItemImg: AllaGricia,
    ItemName: 'Afro Collection',
    ItemPrice: (3).toFixed(2),
    Category: 'Afro Collection',
    attributes: [],
  },

  {
    id: 'great-value-twist-up-lemon-lime-soda-pop-second',
    ItemImg: GreatValueTwist,
    ItemName: 'Afro Collection',
    ItemPrice: (2).toFixed(2),
    Category: 'Afro Collection',
    attributes: [],
  },
  {
    id: 'fanta-orange-soda-second',
    ItemImg: FantaOrange,
    ItemName: 'Afro Wear',
    ItemIngredients: 'Afro Wear, 6 piece collection.',
    ItemPrice: (20),
    Category: 'Afro Collection',
    attributes: [],
  },
  {
    id: 'nigiri-with-salmon-and-orange-1-pc-sale',
    ItemImg: SalmonGunkan,
    ItemName: 'Athleisure Cotion',
    ItemIngredients: 'Athleisure Collection.',
    ItemPriceBefore: (40).toFixed(2),
    ItemPrice: (30).toFixed(2),
    Category: 'Athleisure',
    sale: true,
    attributes: [],
  },

  



  {
    id: 'cheese-pizza-sale',
    ItemImg: CheesePizza,
    ItemName: 'Athleisure 6 Set Minimum ',
    ItemIngredients: 'Athleisure 6 Set Minimum.',
    ItemPriceBefore: (2).toFixed(2),
    ItemPrice: (1).toFixed(2),
    Category: 'Athleisure',
    sale: true,
    attributes: [],
  },


  {
    id: 'nigiri-with-salmon-and-orange-1-pc-sale-second',
    ItemImg: VulcanTobiko,
    ItemName: 'Athleisure',
    ItemIngredients: 'Athleisure.',
    ItemPriceBefore: (40).toFixed(2),
    ItemPrice: (30).toFixed(2),
    Category: 'Athleisure',
    sale: true,
    attributes: [],
  },
  {
    id: 'pepperoni-pizza-sale-second',
    ItemImg: Efichi,
    ItemName: 'Blanketi Piece',
    ItemIngredients:
      'This Blanketi Piece needs 6 items.',
    ItemPriceBefore: (5).toFixed(2),
    ItemPrice: (3).toFixed(2),
    Category: 'Blanketi',
    sale: true,
    attributes: [],
  },
  

  {
    id: 'margherita-pizza-sale-second',
    ItemImg: BBQPizza,
    ItemName: 'Athleisure Col',
    ItemIngredients:
      '6 piece minimum, Athleisure Col.',
    ItemPriceBefore: (8).toFixed(2),
    ItemPrice: (7).toFixed(2),
    Category: 'Athleisure',
    sale: true,
    attributes: [],
  },

  {
    id: 'sebastian-the-crab-roll-8-pcs-sale-second',
    ItemImg: ClockworkOrange,
    ItemName: 'Athleisure',
    ItemIngredients:
      'Athleisure Collection.',
    ItemPriceBefore: (13).toFixed(2),
    ItemPrice: (11).toFixed(2),
    Category: 'Athleisure',
    sale: true,
    attributes: [],
  },

  {
    id: 'nigiri-with-eel-1-pc-second',
    ItemImg: SushiOne,
    ItemName: 'LAD Collection',
    ItemIngredients: 'LAD Collection.',
    ItemPrice: (6).toFixed(2),
    Category: 'LAD Collection',
    attributes: [],
  },
  {
    id: 'vulcan-tobiko-second',
    ItemImg: VulcanTobiko,
    ItemName: 'LAD',
    ItemIngredients: 'LAD Collection.',
    ItemPrice: (4).toFixed(2),
    Category: 'LAD Collection',
    attributes: [],
  },
  {
    id: 'salmon-gunkan-with-cream-cheese-second',
    ItemImg: CucumberMaki,
    ItemName: 'LAD wear',
    ItemIngredients: 'Limited Wear.',
    ItemPrice: (11).toFixed(2),
    Category: 'LAD Collection',
    attributes: [],
  },
  {
    id: 'very-vegetarian-maki-8-pcs-second',
    ItemImg: VeryVegetarian,
    ItemName: 'LAD Collection',
    ItemIngredients: 'LAD Collection.',
    ItemPrice: (5).toFixed(2),
    Category: 'LAD Collection',
    attributes: [],
  },
  
  

]
