import fashion from '../assets/images/icons/fashion.png'
import impact from '../assets/images/icons/impact.png'

const statsPreview = [
    {
        id: 1,
        img:fashion,
        stats: '7',
        name: 'Collections',
    },
    {
        id: 2,
        img:
            'https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/ffffff/external-award-business-kiranshastry-lineal-kiranshastry.png',
        stats: '3',
        name: 'Number of Awards',
    },
    {
        id: 3,
        img:
            'https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/ffffff/external-user-advertising-kiranshastry-lineal-kiranshastry-2.png',
        stats: '10,485 tons',
        name: 'Recycled Textile',
    },
    {
        id: 4,
        img:impact,
        stats: '1000+',
        name: 'Transformed Lives',
    },
]

export default statsPreview;