import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SearchIcon from "../../assets/images/search-icon.png";
import ResetLocation from "../../helpers/ResetLocation";

const EmenuCategories = ({ changeCategory, resetPagination, findMenuItem }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        // Import your categories from the desired file
        const allCategories = require("../../data/AllEfotrCategories").AllEfotrCategories;

        setCategories(allCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []); // Run this effect once when the component mounts

  return (
    <article className="aside-menu">
      <section className="menu-search-section">
        <input
          type="text"
          className="menu-search"
          placeholder="search..."
          onChange={findMenuItem}
        />
        <img src={SearchIcon} alt="" aria-hidden="true" className="menu-search-icon" />
      </section>
      <ul>
        {categories.map((category) => (
          <li key={category.id}>
            <NavLink
              to="/efotr"
              onClick={() => {
                changeCategory(category.name);
                ResetLocation();
                resetPagination();
              }}
            >
              {category.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </article>
  );
};

export default EmenuCategories;
