import React from "react";
import { AllBlogPosts as allBlogPosts } from "../../data/AllProgPosts";
import { useEffect, useState } from "react";
import ResetLocation from "../../helpers/ResetLocation";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SDG1 from '../../assets/images/section-one/SDG1.png'
import SDG4 from '../../assets/images/section-one/SDG4.png'
import SDG5 from '../../assets/images/section-one/SDG5.png'
import SDG10 from '../../assets/images/section-one/SDG10.png'
import img1 from '../../assets/images/section-one/img1.png'
import img2 from '../../assets/images/section-one/img2.png'

const Sustainability = () => {
  const [itemOffset, setItemOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(itemOffset + 5);
  const [currentBlogPosts, setcurrentBlogPosts] = useState([...allBlogPosts].reverse().slice(itemOffset, endOffset));
  const [pageCountPosts, setpageCountPosts] = useState(Math.ceil(allBlogPosts.length / 5));

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 5) % allBlogPosts.length;
    setItemOffset(newOffset);
    ResetLocation();
  };

  useEffect(() => {
    document.title = "Sustainability | Carole Kinoti Brands";
    setEndOffset(itemOffset + 5);
    setcurrentBlogPosts([...allBlogPosts].reverse().slice(itemOffset, endOffset));
    setpageCountPosts(Math.ceil(allBlogPosts.length / 5));

  }, [setEndOffset, endOffset, itemOffset]);
  return (
    <motion.div
      initial={{ opacity: 0, translateX: -300 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: -300 }}
      transition={{ duration: 1 }}
    >
      <main className="ablog">
  <article className="ablog-section">
  <h2 className="ablog-heading" style={{ fontSize: '3rem' }}>
  Making a Difference
</h2>
    <p className="ablog-intro">
      In an age where fashion's environmental footprint has come under scrutiny, 
      Carole Kinoti Brands stands as a testament to the harmony of style and 
      sustainability. Each piece, meticulously crafted, tells a story not just of 
      impeccable design but of eco-conscious choices and responsible sourcing. 
      Our commitment runs deep, prioritizing materials and methods that resonate 
      with the rhythm of our planet. At Carole Kinoti Brands, we believe that 
      fashion should feel good, inside and out. Embrace a brand that mirrors your 
      values, and let's walk the path of sustainable elegance together.
    </p>
    <section className="blog-grid">
            {currentBlogPosts.map((blogPost, index) => {
            
            })}
          </section>
          <div className="banner">
  <LazyLoadImage
    className="banner-img"
    src={img1}
    alt="Image 1"
  />
  <div className="text-container">

    <span className="signature-font">Carole Kinoti Brand</span>
    <h2 style={{ color: 'black', fontSize: '1.5rem', important: 'true' }}>
  YOUR HOME FOR MADE IN KENYA UNIQUE PIECES TAILORED WITH LOVE
</h2>

    <div className="sdg-images">
      <LazyLoadImage
        className="banner-img"
        src={SDG1}
        alt="sdg 1"
      />
      <LazyLoadImage
        className="banner-img"
        src={SDG4}
        alt="sdg 4"
      />
      <LazyLoadImage
        className="banner-img"
        src={SDG5}
        alt="sdg 5"
      />
      <LazyLoadImage
        className="banner-img"
        src={SDG10}
        alt="SDG 10"
      />
    </div>
  </div>
  <LazyLoadImage
    className="banner-img"
    src={img2}
    alt="Image 2"
  />
</div>
  </article>
</main>

    </motion.div>
  );
}
export default Sustainability;