import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook from 'react-router-dom'
import logo from '../../assets/images/logo.png';
import openMenu from '../../assets/images/open-menu.svg';
import closeMenu from '../../assets/images/close-menu.svg';
import { NavLink } from 'react-router-dom';
import SuccessMsg from '../../components/SuccessMsg';
import ResetLocation from '../../helpers/ResetLocation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faShoppingCart, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../contexts/AuthContext';

const Header = ({
  loginModal,
  productsQuantity,
  showModal,
  isModalActive,
  hideMenu,
  activateLoginModal,
}) => {
  const { isLoggedIn, logout } = useAuth(); // Use isLoggedIn from AuthContext
  const navigate = useNavigate(); // Instantiate the navigate function
  
  const handleProfileClick = () => {
    // Navigate to the profile page if the user is logged in
    if (isLoggedIn) {
      navigate('/profile');
    } else {
      activateLoginModal(); // Activate login modal if not logged in
    }
  };

  return (
    <header>
      
      {loginModal}
      <nav className="main-nav flex-container flex-row txt-center">
        <NavLink
          onClick={() => {
            ResetLocation();
            hideMenu();
          }}
          to="/"
          className="logo-styling flex-container flex-row txt-center txt-white"
        >
          <img
            width="100"
            height="100"
            className="logo"
            src={logo}
            alt="Carole's site"
          />
        </NavLink>
        <ul
          className={`navigation-menu flex-row pop-font ${
            isModalActive ? 'active' : ''
          }`}
          style={{ textAlign: 'left !important ' }}
        >
          <li>
            <NavLink
              onClick={() => {
                ResetLocation();
                hideMenu();
              }}
              style={({ isActive }) =>
                isActive
                  ? {
                      textDecoration: 'none',
                      color: '#ff6240',
                    }
                  : {}
              }
              className="txt-white"
              to="/"
            >
              Home
            </NavLink>
          </li>

          <li class="custom-link centered-link">
            <a
              href="https://kambu.carolekinoti.co.ke/"
              rel="noopener noreferrer"
            >
              <div className="centered-text" >
                Kambu <br /> Deals
              </div>
            </a>
          </li>

          <li>
            
            <NavLink
              style={({ isActive }) =>
                isActive
                  ? {
                      textDecoration: 'none',
                      color: '#ff6240',
                    }
                  : {}
              }
              className="txt-white centered-link"
              to="/prog"
            >
              Empowerment <br /> Programs
            </NavLink>
            {/* Second list item */}
            <ul className="sub-menu">
              <li>
                <NavLink
                  onClick={() => {
                    ResetLocation();
                    hideMenu();
                  }}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'none',
                          color: '#ff6240',
                        }
                      : {}
                  }
                  className="txt-white centered-link"
                  to="/prog"
                >
                  Mavazi Elevate Programs
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => {
                    ResetLocation();
                    hideMenu();
                  }}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'none',
                          color: '#ff6240',
                        }
                      : {}
                  }
                  className="txt-white centered-link"
                  to="/progfotr"
                >
                  Fashion On the Road
                </NavLink>
              </li>
            </ul>
          </li>

          <li class="custom-link centered-link">
            <a
              href="https://fotr.carolekinoti.co.ke/"
              rel="noopener noreferrer"
            >
              <div className="centered-text" >
                FOTR <br /> Collections
              </div>
            </a>
          </li>

          <li class="custom-link centered-link">
            <a
              href="https://ckb.carolekinoti.co.ke/"
              rel="noopener noreferrer"
            >
              <div className="centered-text">
                CKB <br /> Collections
              </div>
            </a>
          </li>

          <li>
            <NavLink
              onClick={() => {
                ResetLocation();
                hideMenu();
              }}
              style={({ isActive }) =>
                isActive
                  ? {
                      textDecoration: 'none',
                      color: '#ff6240',
                    }
                  : {}
              }
              className="txt-white"
              to="/sustainability"
            >
              Sustainability
            </NavLink>
          </li>

          <li>
            <NavLink
              onClick={() => {
                ResetLocation();
                hideMenu();
              }}
              style={({ isActive }) =>
                isActive
                  ? {
                      textDecoration: 'none',
                      color: '#ff6240',
                    }
                  : {}
              }
              className="txt-white"
              to="/blog"
            >
              Blogs
            </NavLink>
          </li>

          <li>
            <NavLink
              onClick={() => {
                ResetLocation();
                hideMenu();
              }}
              style={({ isActive }) =>
                isActive
                  ? {
                      textDecoration: 'none',
                      color: '#ff6240',
                    }
                  : {}
              }
              className="txt-white"
              to="/events"
            >
              Events
            </NavLink>
          </li>

          <li>
            <NavLink
              onClick={() => {
                ResetLocation();
                hideMenu();
              }}
              style={({ isActive }) =>
                isActive
                  ? {
                      textDecoration: 'none',
                      color: '#ff6240',
                    }
                  : {}
              }
              className="txt-white"
              to="/contact"
            >
              Contact us
            </NavLink>
          </li>
          
          {/* Profile/Logout Icon */}
        <li>
          <div className="login-and-cart">
          {isLoggedIn ? (
  <div
    className="passive-button-style txt-white profile-icon"
    onClick={() => {
      navigate('/profile'); // Navigate to profile when the user is logged in
    }}
  >
    <FontAwesomeIcon icon={faUser} />
    {/* Profile Icon */}
    <span className="logout-text" onClick={logout}>
      <FontAwesomeIcon icon={faSignOutAlt} /> Log Out
    </span>
  </div>
) : (
              <div
                className="passive-button-style txt-white"
                onClick={() => {
                  ResetLocation();
                  activateLoginModal(); // Use the function to show the login modal
                }}
              >
                <FontAwesomeIcon icon={faUser} /> {/* Log in Icon */}
              </div>
            )}
          </div>
        </li>
        </ul>
        <img
          width="50"
          height="50"
          className="burger-bars"
          src={isModalActive ? closeMenu : openMenu}
          alt={isModalActive ? 'Close menu' : 'Open menu'}
          onClick={showModal}
        />
      </nav>
      <SuccessMsg />
    </header>
  );
};

export default Header;
