
import ImgOne from '../assets/images/our-services/organic-food.webp'
import ImgTwo from '../assets/images/our-services/express-delivery.webp'
import ImgThree from '../assets/images/our-services/unforgable-taste.webp'

const ourServices = [
    {
        id: 1,
        img: ImgOne,
        name: 'Mavazi Elevate Programs',
        description:
            'The Mavazi Elevate Programs (MEP) focuses on elevating fashion industry individuals. They offer valuable knowledge, skills, and mentorship, empowering creativity and entrepreneurship. MEP nurtures talent, boosts confidence, and fosters growth, creating fashion leaders and changemakers.',
    },
    {
        id: 2,
        img: ImgTwo,
        name: 'Fashion On The Road',
        description:
            'Fashion On The Road (FOTR) addresses fashion industry challenges via county tours. They engage women, youth, and individuals with disabilities in Kenyan fashion product creation. FOTR collaborates, sets up collection bins, and promotes sustainability. Their goal is community empowerment, environmental responsibility, and local fashion industry growth with a lasting impact.',
    },
]

export default ourServices;