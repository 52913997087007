import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'; // Import the authentication module


const firebaseConfig = {
    apiKey: "AIzaSyDQNjKlO05LXbjRT9f6IAcTYw2WZfKxx4U",
    authDomain: "ckayb-47be2.firebaseapp.com",
    projectId: "ckayb-47be2",
    storageBucket: "ckayb-47be2.appspot.com",
    messagingSenderId: "531303409580",
    appId: "1:531303409580:web:d9cafe5117525e27e83c09",
    measurementId: "G-B8KF9SBVFZ"
};

const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Auth
const auth = getAuth(app); // Create an instance of Firebase Auth

export { app, db, auth }; // Export it so it can be imported elsewhere