import { useEffect, useState } from "react";
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { app } from '../../firebaseConfig';

const db = getFirestore(app);

const BlogPost = () => {
    const [blogPost, setBlogPost] = useState({});
    const [currentImageIndex, setCurrentImageIndex] = useState(0); // Track currently displayed image
    const [partnerLogos, setPartnerLogos] = useState([]); // Store partner logos

    useEffect(() => {
        document.title = `${blogPost.heading} | Carole Kinoti Brands`;
    }, [blogPost.heading]);

    useEffect(() => {
        const postName = window.location.pathname
            .toString()
            .substring(6)
            .replaceAll('-', ' ');

        console.log("Post Name:", postName);

        const fetchBlogData = async () => {
            try {
                const allBlogsSnapshot = await getDocs(collection(db, "blogPosts"));

                if (!allBlogsSnapshot.empty) {
                    const matchingBlogs = allBlogsSnapshot.docs.filter(doc => {
                        return doc.data().name && doc.data().name.toLowerCase() === postName.toLowerCase();
                    });

                    if (matchingBlogs.length) {
                        const doc = matchingBlogs[0];
                        setBlogPost({ id: doc.id, ...doc.data() });
                    } else {
                        console.error("No blog post found with the given heading");
                    }
                }

            } catch (error) {
                console.error("Error fetching blog post:", error);
            }
        };

        fetchBlogData();
    }, []);

    useEffect(() => {
        // Fetch partner logos from Firestore
        const fetchPartnerLogos = async () => {
            try {
                // Assuming that blogPost.partnerLogos is an array of logo URLs
                const logos = blogPost.partnerLogos || []; // Default to an empty array

                setPartnerLogos(logos);
            } catch (error) {
                console.error("Error fetching partner logos:", error);
            }
        };

        fetchPartnerLogos();
    }, [blogPost.partnerLogos]); // Only fetch partner logos when blogPost.partnerLogos changes

    useEffect(() => {
        // Automatically advance to the next image after a delay (e.g., 6 seconds)
        const timer = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % blogPost.carousel.length);
        }, 4000);

        // Clear the timer when the component unmounts
        return () => clearInterval(timer);
    }, [blogPost.carousel]); // Restart the timer when the carousel images change

    const handlePrevImage = () => {
        // Handle previous image button click
        setCurrentImageIndex(prevIndex => Math.max(prevIndex - 1, 0));
    };

    const handleNextImage = () => {
        // Handle next image button click
        setCurrentImageIndex(prevIndex => Math.min(prevIndex + 1, blogPost.carousel.length - 1));
    };

    // Determine the styleId for the blog post
    const styleId = blogPost.styleId || 1; // Default to styleId 1 if not specified

    // Define the CSS class based on the styleId
    let blogPostClass = `ablog-post style-${styleId}`;

    // Add additional class for styleId 2
    if (styleId === 2) {
        blogPostClass += ' style-2';
    }

    return (
        <div className={blogPostClass}>
            <main className="ablog-post">
                <h1>{blogPost.name}</h1>

                <img
                    src={blogPost.img}
                    alt=""
                    aria-hidden="true"
                    className="style-3-heading-image" // Define this class in your CSS
                />

                <section>
                    {/* Render HTML content using dangerouslySetInnerHTML */}
                    <div dangerouslySetInnerHTML={{ __html: blogPost.content }} />
                </section>
                {/* Display images side by side for styleId 3 */}
                {styleId === 3 ? (
                    // Hide the carousel when styleId is 3
                    null
                ) : (
                    // Display a single image or carousel for other styles
                    blogPost.carousel && blogPost.carousel.length > 0 && (
                        <section className="acarousel-section">
                            {/* Carousel image */}
                            <img
                                src={blogPost.carousel[currentImageIndex]}
                                alt={`Carousel Image ${currentImageIndex + 1}`}
                                className="carousel-image"
                            />
                            {blogPost.carousel.length > 1 && (
                                <div className="carousel-buttons">
                                    {/* Carousel navigation buttons */}
                                    <button onClick={handlePrevImage} className="carousel-button prev">
                                        <span>&#9664;</span>
                                    </button>
                                    <button onClick={handleNextImage} className="carousel-button next">
                                        <span>&#9654;</span>
                                    </button>
                                </div>
                            )}
                        </section>
                    )
                )}
                {/* Our Partners section */}
                {partnerLogos.length > 0 && (
                    <section className="partners-section">
                        <h2>Our Partners</h2>
                        <div className="partner-logos"> {/* Define this class in your CSS */}
                            {partnerLogos.map((logo, index) => (
                                <img
                                    key={index}
                                    src={logo}
                                    alt={`Partner Logo ${index + 1}`}
                                    className="partner-logo" 
                                />
                            ))}
                        </div>
                    </section>
                )}
            </main>
        </div>
    );
}

export default BlogPost;
