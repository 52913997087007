import React from 'react'
import SlideOne from '../../assets/images/About/img-one.png'
import SlideTwo from '../../assets/images/About/img-two.png'
import SlideThree from '../../assets/images/About/img-three.png'
import SlideFour from '../../assets/images/About/img-four.png'


export default class AboutSectionOne extends React.Component {
  render() {
    return (
      <article className="section-one">
        <section className="section-one-content">
          <h2>About us</h2>
          <h3 className='sub-title'>Empowering Kenya, One Stitch at a Time.</h3>
          <p>
          Carole Kinoti Brands is at the forefront of revolutionary fashion in Kenya. Seamlessly blending traditional 
          Kenyan motifs with modern design aesthetics, our brand stands as a beacon of innovation and inclusivity. We're not just about clothes; 
          we're about stories, heritage, and empowerment. Our sustainable practices and collaborations with local artisans put us on the global map, 
          while our commitment to uplifting marginalized communities gives us a unique niche in the industry. We believe fashion should be accessible, ethical, 
          and always inspired. Dive into a curated world of designs with Carole Kinoti Brands – where every stitch tells a story.
          </p>
          <div className="section-one-glass"></div>
        </section>
        <section className="aboutus-img-grid">
          <img src={SlideOne} alt="" aria-hidden="true" />
          <img src={SlideTwo} alt="" aria-hidden="true" />
          <img src={SlideThree} alt="" aria-hidden="true" />
          <img src={SlideFour} alt="" aria-hidden="true" />
        </section>
      </article>
    )
  }
}
