import React from "react";
import EmenuCategories from "./EmenuCategories"; // Updated import
import ScrollButton from "../../components/ScrollBtn";
import EmenuGridItem from "./EmenuGridItem"; // Updated import
import ReactPaginate from 'react-paginate';
import { useState, useEffect } from "react";
import ResetLocation from "../../helpers/ResetLocation";
import { motion } from "framer-motion";

const Emenu = ({ allProducts,
  activeCategory,
  allCategories,
  changeCategory,
  handleAddProduct,
  handleRemoveProduct,
  findMenuItem
}) => {

  const [itemOffset, setItemOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(itemOffset + 5);
  const [currentProducts, setcurrentProducts] = useState([...allProducts].reverse().slice(itemOffset, endOffset));
  const [pageCountProducts, setpageCountProducts] = useState(Math.ceil(allProducts.length / 5));

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 5) % allProducts.length;
    setItemOffset(newOffset);
    ResetLocation();
  };
  const resetPagination = () => {
    setItemOffset(0);
    setEndOffset(5);
  }
  useEffect(() => {
    document.title = `${activeCategory} | Carole Kinoti Brand`;
    setEndOffset(itemOffset + 5);
    setcurrentProducts([...allProducts].reverse().slice(itemOffset, endOffset));
    setpageCountProducts(Math.ceil(allProducts.length / 5));

  }, [allProducts, setEndOffset, endOffset, itemOffset, activeCategory]);
  return (
    <motion.div
      initial={{ opacity: 0, translateX: -300 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: -300 }}
      transition={{ duration: 1 }}
    >
      <main className="menu-main">
        <EmenuCategories
          activeCategory={activeCategory}
          allCategories={allCategories}
          changeCategory={changeCategory}
          resetPagination={resetPagination}
          findMenuItem={findMenuItem}
        />
        {currentProducts.length === 0 ?
          <article className="menu-grid">
            <p className="nothing-found" style={{ color: "black" }}>No results found...</p>
          </article> :
          <article className="menu-grid">
            {currentProducts.map((singleEfotrProduct) => (
              <EmenuGridItem
                key={singleEfotrProduct.id}
                singleEfotrProduct={singleEfotrProduct}
                handleAddProduct={handleAddProduct}
                handleRemoveProduct={handleRemoveProduct}
              />
            ))}
            <ScrollButton />
          </article>}

        <ReactPaginate
          className="pagination"
          breakLabel="..."
          nextLabel=" &#62;"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCountProducts}
          previousLabel="&#60;"
          renderOnZeroPageCount={null}
        />
      </main>
    </motion.div>
  );
}

export default Emenu;
