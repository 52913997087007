import React, { useEffect } from "react";
import { motion } from "framer-motion";
//Components
import ScrollBtn from "../../components/ScrollBtn";
import AboutSectionOne from "./AboutSectionOne";
import AboutSectionTwo from "./AboutSectionTwo";
import AboutSectionThree from "./AboutSectionThree";
import AboutSectionFour from "./AboutSectionFour";
import AboutSectionFive from "./AboutSectionFive";

import carole from '../../assets/images/hero/ckb.jpeg'

const About = () => {
  useEffect(() => {
    document.title = "About Us | Pizza Time";
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0, translateX: -300 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: -300 }}
      transition={{ duration: 1 }}>

          <div className="flex-item flex-image">
            <img src={carole} alt="" className="about-img" />
          </div>
      <main className="about-us">
        <AboutSectionOne />
        <AboutSectionTwo />
        <AboutSectionThree />
        {/** <AboutSectionFour />
        <AboutSectionFive />  */}
        <ScrollBtn />
      </main>
    </motion.div>

  );
}

export default About;