import React from 'react'
import { motion } from "framer-motion";
import outfit1 from '../../assets/images/contact-us/outfit1.png'
import outfit2 from '../../assets/images/contact-us/outfit2.png'
import outfit3 from '../../assets/images/contact-us/outfit3.png'


const ContactUsLanding = () => {
  return (
    <section className="contact-us-landing flex-container flex-row txt-white" >
      <motion.div
        initial={{ opacity: 0, translateX: 1000 }}
        whileInView={{ opacity: 0.8, translateX: 400, }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2 }}
      >
         <img src={outfit2} alt="" className="parallax company-details-image" />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, translateX: 1000 }}
        whileInView={{ opacity: 0.8, translateX: 600, }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2 }}
      >
        <img src={outfit3} alt="" className="parallax company-details-image-two" />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, translateX: 1000 }}
        whileInView={{ opacity: 0.8, translateX: 800, }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2 }}
      >
        {/** <img src={outfit3} alt="" className="parallax company-details-image-three" /> */}
      </motion.div>
      <section className='company-details'>
  <div className="col-lg-5 ps-lg-5">
    <h1 class="section-title mb-4">Fashion with a Heart and Purpose</h1>
    <p>Carole, with her two decades of fashion experience, champions change and empowers communities. Dive into the world of Carole Kinoti Brand, where every stitch tells a story and every design echoes a mission.</p>

    <table class="custom-list">
      <tr>
        <td><strong>Mavazi Elevate Program:</strong> <br/>This is a transformative initiative dedicated to empowering marginalized groups, including women, youth, and people with disabilities, with the skills and resources needed to thrive in the fashion industry</td>
        <td><strong>Fashion on the Road</strong> <br/> Embodies a nationwide initiative, engaging marginalized communities and providing them a platform to showcase their talent through the world of fashion</td>
        <td><strong>Carole Kinoti Brand</strong> <br/> Stands as an influential leader in sustainable fashion, serving as a dynamic umbrella championing transformative change across the industry.</td>
      </tr>
      <tr>
        <td><strong>Award-Winning Vision:</strong> Celebrated as The Retailer at the Women SME Leaders Awards 2022.</td>
        <td><strong>People on the Move:</strong> <br/> The Carole Kinoti Brand is spotlighting talents in art, music, and fashion.</td>
        <td><strong>Mavazi Innovation Hub:</strong> An executive fashion value chain program aimed at incoporating music, design, modeling, and fashion.</td>
      </tr>

    </table>
  </div>
</section>

    </section>
  );

}


export default ContactUsLanding;