import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../firebaseConfig"; // Update the path to where you've initialized Firebase
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook from 'react-router-dom'


const AuthContext = createContext(null);

// Moved inside the AuthProvider component
// export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoginModalActive, setLoginModalActive] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setIsLoggedIn(!!user); // This will set to true if user is not null, otherwise false
    });
    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  const showLoginModal = () => setLoginModalActive(true);
  const hideLoginModal = () => setLoginModalActive(false);
  const navigate = useNavigate(); // Instantiate the navigate function

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // setIsLoggedIn will be set to true in onAuthStateChanged listener
    } catch (error) {
      // Handle errors here, such as displaying a notification
      console.error("Login error:", error);
      throw error; // Throw the error to be handled where login is called
    }
  };


  const logout = async () => {
    try {
      await signOut(auth);
      setIsLoggedIn(false);
      navigate('/'); // Add this line to navigate to home after logging out
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        isLoginModalActive,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Now this custom hook is correctly placed outside but after the `AuthProvider`
export const useAuth = () => useContext(AuthContext);
