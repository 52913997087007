export const AllEfotrCategories = [
  {
    name: "Hariri Collection",
    id: "Hariri Collection",
  },
  {
    name: "Limuru Golf Club",
    id: "Limuru Golf Club",
  },
  {
    name: "Muthaiga Golf CLub",
    id: "Muthaiga Golf CLub",
  },
  {
    name: "Simba Colt Motors",
    id: "Simba Colt Motors",
  },
  {
    name: "Golf Series Kisumu County",
    id: "Golf Series Kisumu County",
  },
  {
    name: "Light International School",
    id: "Light International School",
  },
  {
    name: "FOTR Dt Dobbie",
    id: "FOTR Dt Dobbie",
  },
];
