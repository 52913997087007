import Img1 from "../assets/images/blog/post-1.jpeg";
import Img2 from "../assets/images/blog/post-2.jpeg";
import Img3 from "../assets/images/blog/post-3.jpeg";
import Img4 from "../assets/images/blog/post-4.jpeg";
import Img5 from "../assets/images/blog/post-5.jpeg";
import Img6 from "../assets/images/blog/post-6.png";
import Img7 from "../assets/images/blog/post-7.png";
import Img8 from "../assets/images/blog/post-8.png";
import Img9 from "../assets/images/blog/post-9.png";
import Img10 from "../assets/images/blog/post-10.png";

export const AllBlogPosts = [
  {
    img: Img6,
    name: "Mavazi Innovation Hub",
    intro:
      "Join our thriving community of music, design, modeling, and fashion enthusiasts. To unlock your creative potential here, complete a Mavazi Programme.",
    //date: "May 3, 2023",
    //author: "Tom Wilson",
    id: 6,
    background: `In Kenya's ever-evolving landscape, young entrepreneurs, especially in the fashion industry, face hurdles in realizing their potential due to limited resources and opportunities. Enter the Mavazi Innovation Hub, a transformative ecosystem connecting graduates from various Mavazi Elevate Programs, including the prestigious Mavazi Executive Programme. This hub acts as a collaborative platform, fostering a network of creative minds and fashion entrepreneurs, while also serving as a shared regional marketing, supply, and distribution channel, promoting synergy and growth within the Fashion Value Chain.

    For creatives, the hub offers coaching, training, and mentorship, enabling them to translate their visions into authentic products and services, master marketing, and business management. Beyond individuals, the hub positively impacts local communities, creating jobs, introducing new offerings, providing education, and nurturing talent.
    
    The Mavazi Innovation Hub supports individuals and groups in maintaining quality, standardizing products/services, and achieving scalability. Members gain access to the broader Kenya Mavazi Network, including manufacturers, distributors, industry associations, runway shows, and a gallery of fashion creativity. This interconnected network unlocks growth and collaboration opportunities in the dynamic world of fashion entrepreneurship.
    `,
    goal:`At Mavazi Innovation Hub, our primary goal is to foster a thriving creative ecosystem that empowers artists and designers to excel in their respective fields. We aim to:

    Cultivate Talent: Mavazi is committed to identifying, nurturing, and promoting artistic talent across various creative domains, including music, design, modeling, and fashion. We provide an environment where individuals can explore, develop, and showcase their creative abilities.
    
    Community Building: We aspire to create a supportive and collaborative community of artists, designers, and innovators. Through networking and cooperation, our members can learn from each other and collaborate on exciting projects.
    
    Skills Enhancement: Mavazi offers programmes and resources designed to enhance the skills and knowledge of our members. Whether it's mastering a musical instrument, refining design techniques, or learning about the latest fashion trends, we provide the tools for growth.
    
    Pathway to Success: Our ultimate goal is to help our members turn their creative passions into successful careers. We offer guidance, mentorship, and opportunities for them to break into the creative industries and achieve recognition.`,
    admission:`To become a member of Mavazi Innovation Hub, individuals must meet the following admission requirements:

    *Important: Prospective members must have successfully completed a recognized Mavazi programme or course in their chosen creative field. This ensures a baseline level of skills and knowledge.
    
    Portfolio Submission: Applicants are required to submit a portfolio of their work, showcasing their creative abilities. The portfolio should demonstrate their passion and commitment to their chosen field.
    
    Interview and Evaluation: All applicants will undergo an interview and evaluation process conducted by our admissions team. This process allows us to assess the applicant's dedication, creativity, and potential to contribute to the Mavazi community.
    
    Passion for Creativity: We seek individuals who are genuinely passionate about their craft and who share our commitment to artistic innovation and expression.`
  },
  {
    img: Img7,
    name: "Mavazi Sister to Sister Mashinani Mentorship Programme",
    intro:
      "Mavazi Sister to Sister Mashinani Mentorship, in partnership with Carole Kinoti Brands, fosters mentorship and skill-sharing among women in rural communities, promoting empowerment and entrepreneurship.",
    //date: "April 11, 2023",
    //author: "Kerry Smith",
    id: 7,
    background: `In today's world, countless young people, especially in Kenya, grapple with a myriad of challenges as they seek their rightful place in society. The youth, constituting over 20% of Kenya's population, confront ongoing marginalization due to limited access to education, skills development, employment opportunities, and essential healthcare and social services. These hardships place many at risk of engaging in detrimental behaviors like substance abuse, thereby impeding the nation's progress.

    The Sister to Sister program shares a profound commitment to address the plight of Kenya's most disadvantaged youth. This encompasses those residing in urban slums, rural regions, young mothers, and school dropouts, all striving to make ends meet with scarce resources. Many of these individuals teeter on the precipice of despair, lacking the means to break free from their challenging circumstances.
    
    At its core, Sister to Sister prioritizes the empowerment of women and young people. It complements broader societal endeavors to combat the multifaceted issues associated with idle youth groups. Through the Sister to Sister initiative, we endeavor to provide essential skills training and transformative opportunities to this underserved demographic, offering a glimmer of hope to those who have long been denied the chance to unleash their full potential and contribute positively to their communities and the nation.`,
    goal:`The primary goal of the MAVAZI Sister to Sister Mashinani Mentorship is to empower, educate, and elevate women from grassroots levels by providing them with resources, knowledge, and a supportive network. By creating a platform where women can share their experiences, learn from one another, and access professional and personal growth resources, the programme aims to build a self-sustaining community of strong, resilient, and empowered women leaders, entrepreneurs, and change-makers in Mashinani regions.`,
    admission:`Residency: Applicants should be residents of Mashinani regions or have strong ties to these areas.
    Commitment: A genuine commitment to personal growth and a desire to contribute to the betterment of the community.
    Age: Women of all ages are encouraged to apply, but preference may be given to those in early or transitional stages of their careers or businesses, as they can most benefit from mentorship.
    Application: A completed application form detailing personal and professional aspirations, and why the applicant feels they would be a good fit for the mentorship programme.
    Recommendations: Two letters of recommendation from individuals (not family members) who can speak to the applicant's character, aspirations, and potential.
    Interview: Shortlisted candidates may be called in for an interview, either in person or via a digital platform.
    Applicants should note that while prior experience or education is a plus, the main focus of the selection process is the applicant's potential, passion, and commitment to both personal growth and the upliftment of their communities.`
  },
  {
    img: Img8,
    name: "Mavazi Self Help Programme",
    intro:
      "Mavazi Self Help Programme, in collaboration with Carole Kinoti Brands, empowers individuals to build self-sustaining businesses through skill development and financial independence.",
    //date: "April 2, 2023",
    //author: "Kira Wilson",
    //id: 8,
    background: `In today's dynamic world, a significant number of young people, particularly in Kenya, find themselves grappling with multifaceted challenges, each obstacle testing their ability to find their place in society. Kenya's youthful population, accounting for over 20% of the total, confronts persistent marginalization driven by restricted access to education, skill-building opportunities, viable employment prospects, and essential healthcare and social services. This uphill battle often pushes them towards social issues like substance abuse, thwarting the nation's pursuit of its ambitious goals.

    The Mavazi Self-Help Programme (MSHP) emerges as a targeted response to the pressing needs of Kenya's most underserved youth. This encompasses individuals from urban slums, rural areas, young mothers, and school dropouts, all striving to make ends meet amid limited resources. Many of these young souls stand at the precipice of despair, lacking the means to escape their challenging circumstances.
    
    At the heart of the Mavazi Self-Help Programme lies a steadfast commitment to empowering women and youths, aligning with broader societal endeavors to address the complexities associated with idle youth groups. Through MSHP, our primary objective is to offer essential skills training and transformative opportunities to this marginalized demographic. This initiative extends a lifeline to those who have long been denied the chance to harness their full potential and become catalysts for positive change in their communities and across the nation.`,
    goal:`The primary aim of the MAVAZI SELF-HELP PROGRAM is threefold:

    1. Rebuild: To help individuals rebuild their lives, equipping them with the essential skills, knowledge, and confidence they need to restart.
    
    2. Repurpose: To guide participants in finding a new and meaningful purpose in their lives, enabling them to contribute positively to society.
    
    3. Reintegrate: To foster a sense of belonging and connection, ensuring that participants seamlessly reintegrate into the community as empowered, responsible, and positive contributors.`,
    admission:`Commitment to Change: Participants should demonstrate a genuine desire and commitment to bring about positive change in their lives.

    1. Age Requirement: Open to individuals aged 18 years and above. For those under 18, parental or guardian consent is mandatory.
    
    2. Residency: Applicants should be residents of the community where the programme is being held or be willing to travel/relocate for the duration of the programme.
    
    3. Background Check: A basic background check may be conducted to ensure the safety and integrity of the programme.
    
    4. Health Assessment: Participants might need to undergo a basic health and psychological assessment to ensure they receive the necessary support during the programme.
    
    5. Personal Essay: A brief essay explaining why they wish to join the programme, their expectations, and what challenges they hope to overcome.
    
    Applications will be reviewed on a rolling basis, and selected participants will be informed via email or phone. Space is limited, and selections will be made based on the alignment of the applicant's needs with the programme's objectives.`
  },
  {
    img: Img9,
    name: "Mavazi Elevate Programme, Sewing Machine Operators (SMO)",
    intro:
      "The Mavazi Elevate Programme is a transformative initiative dedicated to equipping Kenya's underprivileged youth and young mothers with essential skills and opportunities, paving the way for a brighter future and stronger communities.",
    //date: "March 18, 2023",
    //author: "George Denson",
    id: 9,
    background: `In today's world, many young people face numerous challenges and often struggle to find their place in society. This is particularly true in Kenya, where youth make up over 20% of the population and continue to face marginalization. Limited access to education, skills development, viable employment opportunities, as well as inadequate access to health and social services, has left many of them vulnerable to engaging in social ills such as substance abuse. These challenges hinder the nation's progress towards realizing its goals and ambitions.

    The Mavazi Elevate Programme is specifically designed to address the needs of the most underprivileged youth in Kenya. This includes those residing in urban slums, rural areas, young mothers, and school dropouts who are struggling to make a living with limited resources. Many of these young individuals are on the brink of losing hope as they lack the means to escape their challenging circumstances.
    
    The programme places a strong emphasis on empowering women and youths, aiming to complement the broader societal efforts to combat the issues associated with idle youth groups. Through the Mavazi Elevate Programme (MEP), we seek to provide essential skills training and opportunities to this underserved demographic.`,
    goal:`Programme Goal:
    The primary goal of the Mavazi Elevate Programme is to accelerate learning, provide employment opportunities, and equip young people with the skills and support necessary to achieve lifelong personal and professional success. By doing so, the programme aims to fundamentally change the life trajectories of the participants.
    
    Additionally, MEP focuses on addressing the skill challenges faced by employers by providing them with a valuable source of vibrant entry-level talent. This not only benefits the participants but also contributes to the overall economic growth and development of the region.`,
    admission:`To be eligible for the Mavazi Elevate Programme, prospective participants must meet the following admission requirements:

    Age Criteria: Applicants must fall within the designated age group for the programme. Specific age requirements may vary depending on the programme's target demographic.
    
    Residency: Participants must either reside in urban slums, rural areas, or meet other residency criteria established by the programme.
    
    Educational Background: While the programme may cater to individuals who have not completed their formal education, a willingness to learn and acquire new skills is essential.
    
    Commitment: Prospective participants should be committed to completing the programme and actively participating in all training and skill development activities.
    
    Selection Process: The programme may employ a selection process to identify individuals who are most in need of support and empowerment.
    
    Gender and Demographic Considerations: MEP is inclusive and strives to empower both women and youths from diverse backgrounds. The programme may have specific provisions to ensure gender and demographic inclusivity.
    
    By meeting these admission requirements, young individuals can embark on a journey of skill development and personal growth through the Mavazi Elevate Programme, ultimately enhancing their prospects for a better future.`
  },
  {
    img: Img10, Img4,
    name: "The Mavazi Executive Programme",
    intro:
      "The Mavazi Executive Programme, in strategic partnership with Strathmore Business School, offers tailored leadership and management training for aspiring fashion industry leaders.",
   // date: "March 7, 2023",
   // author: "Alex Lee",
    id: 10,
   // content: `one,two`,
    background: `The "Mavazi Executive Programme (MEP)" is a training programme that will instil enhanced design and business skills amongst the established and upcoming designers that participate in it.

    The program will also provide the participants opportunities to develop and enhance their networks, business structures, systems, cultures and brand visibility. With these new skills, opportunities and information the participants will enjoy an extended customer base, a rich network in the fashion value chain, increased revenues and potential access to financing.
    
    Upon successful completion of the programme created by the Strathmore University Business School in partnership with the Carole Kinoti Brands' participants will have an opportuinity to join an exclusive network of Fashion Value chain players committed to creating sustainable 'Made in Africa' fashion trends known as the Mavazi Hub.
    
    Shared regional marketing, supply and distribution channels will create valuable synergies that will bring about economies of scale and an enhanced negotiating platform in the Fashion Value Chain.
    
    Target Participants
This programme is highly recommended for established and upcoming creatives in the Textile, Apparel and Handicrafts Industry who aim to create sustainable fashion trends with a Made in Africa agenda. More specifically:
- Fashion Designers (Couture, Ready to Wear
- Fashion, Fast Fashion, Economy Fashion)
- Jewelry and Accessories Designers
- Footwear Designers
- Bag Designers
- Hat Designers
    
    `,
    goal: `1. Enhance design and business skills amongst the participants.
    2. Develop sustainable networks with players throughout the Fashion Value Chain to encourage synergy.
    3. Empower participants to develop effective and ethical plans, brands, cultures, structures and systems for their businesses.
    4. Embed a customer-centric approach in the Fashion Design business.
    5. Understand the position and role of the Designer in the Fashion Value Chain.
    
    Key Benefits
1. Increased overall revenues by using new marketing, design and networking insights
2. Extended networks amongst players in the Fashion Value Chain
3. Enhanced design and business skills
4. Opportunity to be a part of an exclusive community in the Fashion Value Chain
5. Creating and elevating the brand visibility of the participants' businesses
    
    `,
    admission: `
    To maintain the integrity and ensure the success of the MAVAZI Executive Programme, we've set specific admission criteria. These criteria help us identify passionate, committed, and deserving women entrepreneurs who can benefit most from our offerings.

Background & Experience:

A minimum of 1 year of experience in any business sector, preferably related to crafts, arts, or any creative field.
Prior ownership or leadership role in a micro or small enterprise will be an added advantage.
Educational Qualification:

While formal education is not a strict requirement, basic literacy and numeracy skills are essential.
Candidates with formal education in business, arts, or any related field will be given preference.
Digital Proficiency:

Basic knowledge of digital platforms (e.g., email, social media) is expected. The programme's aim is to enhance this digital skillset further.
Vision & Commitment:

A clear business vision or idea that can benefit from transitioning to an e-commerce platform.
Demonstrated commitment to pursuing the business full-time and leveraging the resources the programme offers.
Recommendations & References:

Two letters of recommendation from non-family members attesting to the candidate's entrepreneurial spirit, character, and dedication.
References from previous clients or business partners will be considered an added advantage.
Geographic Criteria:

Preference will be given to women entrepreneurs hailing from marginalized or underserved areas, as one of the programme's goals is to uplift these regions.
Age Limit:

Women aged 18 and above are eligible to apply. There's no upper age limit, as we believe in fostering entrepreneurial spirit at any age.
    `,
    application: `What is needed`,
    timeline:`ploo`
  },

  {
    img: Img1,
    name: "The Mavazi Internship Programme",
    intro:
      "Welcome to the Mavazi Internship Programme (MEP) a transformative ten-week journey at the intersection of fashion, humanitarianism, and reproductive health.",
   // date: "March 7, 2023",
   // author: "Alex Lee",
    id: 10,
   // content: `one,two`,
    background: `In the contemporary world, countless young individuals, especially in Kenya, face an array of challenges as they navigate the complexities of society. Kenya's youth, comprising more than 20% of the population, confront ongoing marginalization due to limited access to education, skills development, viable employment opportunities, and essential healthcare and social services. These hurdles often lead to vulnerability, with many youth falling prey to social issues like substance abuse, thus impeding the nation's progress.

    The Mavazi Internship Programme (MIP) is strategically tailored to meet the pressing needs of Kenya's most disadvantaged youth. This includes those hailing from urban slums, rural areas, young parents, and school dropouts, all grappling with the daily struggle of making ends meet amidst meager resources. A significant number of these young individuals teeter on the brink of hopelessness, lacking the means to break free from their challenging circumstances.
    
    Central to the Mavazi Internship Programme is the empowerment of women and young people, with a resolute aim to complement broader societal efforts in addressing the issues related to idle youth groups. Through MIP, our mission is to provide critical skills training and transformative opportunities to this underserved demographic. This initiative kindles a beacon of hope for those who have long been denied the chance to unlock their full potential and make a positive impact on their communities and the nation as a whole.`,
    goal: `Goals:

    Skill Development: The primary goal of the MEP internship programme is to provide interns with practical skills and knowledge relevant to the fashion industry, humanitarian efforts, and reproductive health advocacy. Participants will receive comprehensive training and guidance to enhance their abilities.
    
    Empowerment: We aim to empower interns by offering them opportunities to work on real projects and initiatives that have a positive impact on society. Through meaningful work, interns will develop a sense of purpose and responsibility.
    
    Networking: MEP facilitates networking opportunities with professionals in the fashion industry. Interns will build valuable connections that can shape their future careers.
    
    Awareness: Interns will gain a deeper understanding of humanitarian challenges, reproductive health issues, and the intersection of fashion and social responsibility. This programme aims to raise awareness and inspire interns to become advocates for positive change.`,
    admission: `
To be eligible for the Mavazi Internship Programme (MEP), applicants must meet the following admission requirements:

Educational Background: Candidates should have a strong educational background in fields related to fashion, humanitarian work, or reproductive health. This may include degrees or coursework in fashion design, public health, international relations, or related disciplines.

Age and Eligibility: The programme is open to individuals aged 18 to 30. Applicants should be enthusiastic, passionate about social impact, and committed to learning and personal growth.

Availability: Interns must be available for the entire ten-week programme duration. They should commit to full-time participation and actively engage in all programme activities.

Application Process: Prospective interns must submit a comprehensive application, including a resume or CV, a statement of purpose outlining their motivations and career aspirations, and any relevant portfolio or academic transcripts.

Interview: Shortlisted candidates will be invited for an interview to assess their suitability for the 10. This interview may include discussions about their background, skills, and alignment with the programme's goals.

Commitment to Values: MEP is committed to diversity, inclusivity, and ethical practices. Applicants should demonstrate alignment with these values in both their application and interview.

The Mavazi Internship Programme (MEP) offers a unique opportunity for young individuals to contribute to positive change while gaining valuable skills and experiences. It is a stepping stone toward a promising career in fashion, humanitarian work, or reproductive health advocacy.
    `,
    application: `What is needed`,
    timeline:`to do`
  },



];


