import React from "react";
import ImgOne from "../../assets/images/about-us/img-1.png";
import ImgTwo from "../../assets/images/about-us/img-2.png";
import ImgThree from "../../assets/images/about-us/img-3.png";
import img375 from '../../assets/images/section-one/section-one-375.png'
import img700 from '../../assets/images/section-one/section-one-700.png'
import img1440 from '../../assets/images/section-one/section-one-1440.png'
import { LazyLoadImage } from "react-lazy-load-image-component";

export default class AboutSectionThree extends React.Component {
  render() {
    return (
      <article className="section-three">
        <div className="section-three-img-grid">
          <img src={ImgOne} alt="" aria-hidden="true" />
          <img src={ImgTwo} alt="" aria-hidden="true" />
          <img src={ImgThree} alt="" aria-hidden="true" />
        </div>
        <section className="section-three-content">
          <h2 className="sub-title">Crafting Authenticity: Where Fashion Meets Family</h2>
          <h3>Over 1000+ transformed lives</h3>
          <p>
          At the heart of Carole Kinoti Brands is our 
          unwavering dedication to authenticity, innovation, 
          and excellence in fashion. Every garment in our 
          collection exudes a blend of contemporary design and 
          the timeless allure of Kenyan traditions. Sourced from 
          the finest local materials and crafted with precision, 
          our ensembles not only showcase style but also resonate 
          with the passion and stories of our homeland. But, fashion, 
          for us, is not just about the apparel. It's about the entire 
          experience. As you step into our world, we strive to make 
          you feel valued, heard, and celebrated. Our skilled team 
          is steadfast in their commitment to ensuring that every 
          interaction with our brand leaves an indelible mark of 
          warmth and belonging. With Carole Kinoti Brands, you are 
          not merely a customer; you become a cherished member of 
          our ever-growing family
          </p>
        </section>
    
      <LazyLoadImage
        className="section-two-img"
        src={img375}
        srcSet={`${img1440} 1440w, ${img700} 700w, ${img375} 375w`}
        sizes="(min-width: 1440px) 1440px, (min-width: 700px) 700px, 375px"
        alt="Pizza Time restaurant interior with people at the tabel and the staff serving the customers"
      />
      </article>
    );
  }
}
